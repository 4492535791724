import { 
    getAllData,

} from '@/store/config/method'

const requestInit = {
    page: 1,
    show: 10,
    sortBy: null,
    sorting: null,
    search: null
}

const itemInit = {
    id: null,

}

const initialState = () => ({
    msg: null,
    status: 0,
    data: {},
    request: {...requestInit},
    item: {...itemInit},
    roles: []
})

const state = initialState

const getters = {
    getMsg: state => state.msg,
    getStatus: state => state.status,
    getItem: state => state.item,
    getRequest: state => state.request,
    getData: state => state.data || {},
    getRoles: state => state.roles || [],
}

const actions = {
    resetRequest({commit }) {
        commit('SET_REQUEST', {...requestInit})
    },

    setItem({commit }, value) {
        commit('SET_ITEM', {...value})
    },

    resetItem({commit }) {
        commit('SET_ITEM', {...itemInit})
    },

    async getAllRole({commit }) {
        try {
            const response = await getAllData(`/roles`)
            commit('SET_RESPONSE', response.data)
            commit('SET_ROLES', response.data.data)
        } catch (e) {
            if (e.response) {
                commit('SET_FAILED_MESSAGE', e.response)
            } else {
                commit('SET_FAILED')
            }
        }
    },

    resetState({commit }) {
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.msg = "Jaringan Bermasalah"
        state.status = 0
    },

    SET_FAILED_MESSAGE(state, error) {
        state.msg = error?.data?.msg || 'Jaringan Bermasalah'
        state.status = error?.data?.status || 0
    },

    SET_RESPONSE(state, response) {
        state.msg = response.msg
        state.status = response.status
    },

    SET_DATA(state, value) {
        state.data = value
    },

    SET_REQUEST(state, value){
        state.request = value
    },

    SET_ITEM(state, value){
        state.item = value
    },
    
    SET_ROLES(state, value) {
        state.roles = value
    },

    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}