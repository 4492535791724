import { 
    getAllData,
    postData
} from '@/store/config/method'

const itemInit = {
    id: null,
    name: null,
    email: null,
    whatsapp: null,
    sms: null,
    pin_bb: null,
    ym1: null,
    ym2: null,
    line: null,
    wechat: null,
    pengumuman: null,
    komisi: null,
    meta_keyword: null,
    meta_description: null,
    meta_title: null,
    status: null,
    list_google_analytics: null, 
    live_chat: null, 
    google_text_manager: null, 
    google_site_verification: null
}

const initialState = () => ({
    msg: null,
    status: 0,
    data: {},
    item: {...itemInit},
})

const state = initialState

const getters = {
    getMsg: state => state.msg,
    getStatus: state => state.status,
    getData: state => state.data || {},
    getItem: state => state.item,
}

const actions = {
    resetItem({commit}){
        commit('SET_ITEM',{...itemInit})
    },
    setItem({commit}, value){
        commit('SET_ITEM',{...value})
    },

    async fetchData({
        commit, state
    }){
        try {
            const response = await getAllData(`/web`,state.request);
            commit('SET_RESPONSE', response.data);
            commit('SET_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },

    async store({
        commit
    }, data) {
        try {
            const response = await postData('/web',data);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },

    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.msg = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        state.msg = error?.data?.msg || 'Jaringan Bermasalah'
        state.status = error?.data?.status || 0
    },
    SET_RESPONSE(state, response) {
        state.msg = response.msg
        state.status = response.status
    },
    SET_DATA(state, value) {
        state.data = value
    },
    SET_REQUEST(state, value){
        state.request = value
    },
    SET_ITEM(state, value){
        state.item = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}