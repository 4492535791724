function loadTransaction(view) {
    return () => import( /* webpackChunkName: "view-transaction" */ `../views/Transaction/${view}.vue`)
}

const routerTransaction = [
    {
        path: '/withdraw',
        name: 'ViewWithdrawData',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: []
        },
        component: loadTransaction('ViewWithdraw')
    },
    {
        path: '/deposit',
        name: 'ViewDepositData',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: ['superadmin']
        },
        component: loadTransaction('ViewDeposit')
    }
]

export default routerTransaction