import { 
    getDataWithParams,
    destroyData,
    uploadData,
    getAllData,
    postData
} from '@/store/config/method'

const requestInit = {
    page: 1,
    show: 10,
    sortBy: null,
    sorting: null,
    search: null,
}
const itemInit = {
    id: null,
    name: null,
    status: null,
    type: null,
    potongan: null,
    logo: null,
}
const fileDataInit = {
    url: null,
    name: null,
    size: null,
    isImage: false,
    isVideo: false,
    file: null
}

const initialState = () => ({
    msg: null,
    status: 0,
    data: {},
    request: {...requestInit},
    item: {...itemInit},
    fileData: {...fileDataInit},
    bankStatus: [
        {
            status: 0,
            label: 'Offline',
            type: 'primary'
        },
        {
            status: 1,
            label: 'Online',
            type: 'success'
        },
    ],
    banks: [],
    fileDetail: {
        name: null,
        fullName: null
    },
})

const state = initialState

const getters = {
    getMsg: state => state.msg,
    getStatus: state => state.status,
    getData: state => state.data || {},
    getRequest: state => state.request,
    getItem: state => state.item,
    getBankStatus: state => (status) => state.bankStatus.find(x => x.status == status),
    getBanks: state => state.banks || [],
    getFileData: state => state.fileData,
    getFileNewName: state => state.fileDetail?.fullName || null,
}

const actions = {
    resetRequest({commit}){
        commit('SET_REQUEST',{...requestInit})
    },
    resetItem({commit}){
        commit('SET_ITEM',{...itemInit})
    },
    setItem({commit}, value){
        commit('SET_ITEM',{...value})
    },
    resetFileData({commit}){
        commit('SET_FILE_DATA',{...fileDataInit})
    },
    setFileData({commit}, value){
        commit('SET_FILE_DATA', value)
    },
    resetFileName({commit}){
        commit('SET_FILE_NEW_NAME',{
            name: null,
            fullName: null,
        })
    },

    async fetchData({
        commit, state
    }){
        try {
            const response = await getDataWithParams(`/bank`,state.request);
            commit('SET_RESPONSE', response.data);
            commit('SET_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },

    async getAllBank({commit }) {
        try {
            const response = await getAllData(`/all-bank`)
            commit('SET_RESPONSE', response.data)
            commit('SET_BANKS', response.data.data)
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },

    async store({
        commit
    }, data) {
        try {
            const response = await uploadData('/add-bank',data);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },

    async update({commit }, {payload, id}) {
        try {
            const url = `/update-bank/${id}`
            const response = await uploadData(url, payload)
            commit('SET_RESPONSE', response.data)
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },

    async updateStatus({commit }, data) {
        try {
            const url = `/status-bank/${data.id}`
            const payload = {...data}
            delete payload.id
            const response = await postData(url)
            commit('SET_RESPONSE', response.data)
        } catch (e) {
            if (e.response) {
                commit('SET_MSG_FAILED', e.response)
            } else {
                commit('SET_FAILED')
            }
        }
    },

    async updateAllStatus({commit }, data) {
        try {
            const response = await postData(`/status-bank/all`, data)
            commit('SET_RESPONSE', response.data)
        } catch (e) {
            if (e.response) {
                commit('SET_MSG_FAILED', e.response)
            } else {
                commit('SET_FAILED')
            }
        }
    },
    
    async destroy({
        commit
    }, data) {
        try {
            const url = `bank/${data.id}`
            const response = await destroyData(url);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.msg = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        state.msg = error?.data?.msg || 'Jaringan Bermasalah'
        state.status = error?.data?.status || 0
    },
    SET_RESPONSE(state, response) {
        state.msg = response.msg
        state.status = response.status
    },
    SET_DATA(state, value) {
        state.data = value
    },
    SET_REQUEST(state, value){
        state.request = value
    },
    SET_ITEM(state, value){
        state.item = value
    },
    SET_BANKS(state, value){
        state.banks = value
    },
    SET_FILE_NEW_NAME(state, response){
        state.fileDetail = {
            name: response.fileName,
            fullName: response.fullName
        }
    },
    SET_FILE_DATA(state, value){
        state.fileData = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}