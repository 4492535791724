import { 
    getDataWithParams,
    destroyData,
    getAllData,
    postData
} from '@/store/config/method'

const requestInit = {
    page: 1,
    show: 10,
    sortBy: null,
    sorting: null,
    search: null,
}
const itemInit = {
    id: null,
    name: null,
    status: null,
    no_rek: null,
    id_bank: null,
}

const initialState = () => ({
    msg: null,
    status: 0,
    data: {},
    request: {...requestInit},
    item: {...itemInit},
    rekeningStatus: [
        {
            status: 0,
            label: 'Tidak Aktif',
            type: 'primary'
        },
        {
            status: 1,
            label: 'Aktif',
            type: 'success'
        },
    ],
    rekenings: [],
})

const state = initialState

const getters = {
    getMsg: state => state.msg,
    getStatus: state => state.status,
    getData: state => state.data || {},
    getRequest: state => state.request,
    getItem: state => state.item,
    getRekeningStatus: state => (status) => state.rekeningStatus.find(x => x.status == status),
    getRekenings: state => state.rekenings || [],
}

const actions = {
    resetRequest({commit}){
        commit('SET_REQUEST',{...requestInit})
    },
    resetItem({commit}){
        commit('SET_ITEM',{...itemInit})
    },
    setItem({commit}, value){
        commit('SET_ITEM',{...value})
    },

    async fetchData({
        commit, state
    }){
        try {
            const response = await getDataWithParams(`/rekening`,state.request);
            commit('SET_RESPONSE', response.data);
            commit('SET_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },

    async getAllRekening({commit }) {
        try {
            const response = await getAllData(`/all-rekening`)
            commit('SET_RESPONSE', response.data)
            commit('SET_REKENINGS', response.data.data)
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },

    async store({
        commit
    }, data) {
        try {
            const payload = {...data}
            delete payload.id
            const response = await postData('/add-rekening',payload);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },

    async update({commit }, data) {
        try {
            const url = `/update-rekening/${data.id}`
            const payload = {...data}
            delete payload.id
            const response = await postData(url, payload)
            commit('SET_RESPONSE', response.data)
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },

    async updateStatus({commit }, data) {
        try {
            const url = `/status-rekening/${data.id}`
            const payload = {...data}
            delete payload.id
            const response = await postData(url)
            commit('SET_RESPONSE', response.data)
        } catch (e) {
            if (e.response) {
                commit('SET_MSG_FAILED', e.response)
            } else {
                commit('SET_FAILED')
            }
        }
    },
    
    async destroy({
        commit
    }, data) {
        try {
            const url = `rekening/${data.id}`
            const response = await destroyData(url);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.msg = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        state.msg = error?.data?.msg || 'Jaringan Bermasalah'
        state.status = error?.data?.status || 0
    },
    SET_RESPONSE(state, response) {
        state.msg = response.msg
        state.status = response.status
    },
    SET_DATA(state, value) {
        state.data = value
    },
    SET_REQUEST(state, value){
        state.request = value
    },
    SET_ITEM(state, value){
        state.item = value
    },
    SET_REKENINGS(state, value){
        state.rekenings = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}