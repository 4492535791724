const initialState = () => ({
    sidebar: []
})
const state = initialState

const getters = {
    getSidebar: state => state.sidebar,
}

const actions = {
    setSideBar({commit}, value){
        commit('SET_SIDEBAR', value)
    }
}

const mutations = {
    SET_SIDEBAR(state, value) {
        state.sidebar = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}