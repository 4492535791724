import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import VuexPersistence from 'vuex-persist';

import localForage from 'localforage';
const vuexStorage = new VuexPersistence({
  key: 'una4d',
  storage: localForage,
  reducer: state => ({ auth: state.auth }),
  asyncStorage: true
})
const vuexStorageUser = new VuexPersistence({
  key: 'una4d-users',
  storage: localForage,
  reducer: state => ({ superadmin: state.superadmin, sidebar: state.sidebar}),
  asyncStorage: true
})
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoggedIn: !!localStorage.getItem('appkey'),
    role: localStorage.getItem('una4d-role'),
    activeTab: 'Dashboard',
    snackbar: {
      type: "success",
      text: "message",
      withclose: true,
      show: false
    },
    breadcrumb: [],
    loading: 0,
    collapse: false,
    modal: null
  },
  getters: {
    getLoading: state => state.loading,
    getisLoggedIn: state => state.isLoggedIn,
    getRole: state => state.role,
    getActiveTab: state => state.activeTab,
    getSnackbar: state => state.snackbar,
    getBreadcrumb: state => state.breadcrumb,
    getCollapse: state => state.collapse,
    getModal: state => state.modal
  },
  actions: {
    setActiveTab({commit}, value){
      commit('SET_ACTIVETAB', value)
    },
    setBreadCrumb({commit}, value){
      commit('SET_BREADCRUMB', value)
    },
    setModal({state}, value){
      state.modal = value
    },
    setLoading({commit}, value){
      commit('SET_LOADING', value);
    },
    showSnackbar({commit}, {
        type = "success",
        text = "message",
        withclose = true,
        show = true
      }){
        var value ={
          type: type,
          text: text,
          withclose: withclose,
          show: show
        }
      commit('SET_SNACKBAR', value);
    },
    hideSnackbar({commit}){
        commit('HIDE_SNACKBAR');
    },
    logoutStorage({commit,state}){
      localStorage.clear();
      state.isLoggedIn = false
      const initial = modules
      Object.keys(initial).forEach(key =>  commit(`${key}/resetState`))
    }
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_ACTIVETAB(state, value) {
      state.activeTab = value;
    },
    SET_SNACKBAR(state, value) {
      state.snackbar = value;
    },
    HIDE_SNACKBAR(state) {
      state.snackbar.show = false;
    },
    SET_BREADCRUMB(state, value) {
      state.breadcrumb = value;
    },
    SET_COLLAPSE(state) {
      state.collapse = !state.collapse;
    },
    RESET_COLLAPSE(state) {
      state.collapse = false;
    },
  },
  modules: {
    ...modules,
  },
  plugins: [vuexStorage.plugin, vuexStorageUser.plugin]
})