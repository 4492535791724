import client from './index'
import store from '@/store'

const getDataWithParams = (url,request) => client['api'].get(url, {params: request})
const getAllData = (url) => client['api'].get(url)
const postData = (url,data) => client['api'].post(url,data)
const patchData = (url,data) => client['api'].patch(url,data)
const putData = (url,data) => client['api'].put(url,data)
const destroyData = (url) => client['api'].delete(url)
const uploadData = (url,data) => client['api'].post(url, data, {"Content-Type": "multipart/form-data",  onUploadProgress: progressEvent => {
    let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
    store.dispatch('setLoading',percentCompleted)
}})
const downloadFile = (url) => client['api'].get(url, { responseType: 'blob'})

export {
    getDataWithParams,
    getAllData,
    postData,
    patchData,
    destroyData,
    uploadData,
    downloadFile,
    putData
}
