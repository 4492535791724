import { 
    getAllData,
    postData
} from '@/store/config/method'

const requestInit = {
    page: 1,
    show: 10,
    sortBy: null,
    sorting: null,
    search: null,
}
const itemInit = {
    id: null,
    max: null,
    diskon: null,
    kei: null,
    menang: null,
}

const initialState = () => ({
    msg: null,
    status: 0,
    data: {},
    request: {...requestInit},
    item: {...itemInit},
    game: [],
})

const state = initialState

const getters = {
    getMsg: state => state.msg,
    getStatus: state => state.status,
    getData: state => state.data || {},
    getRequest: state => state.request,
    getItem: state => state.item,
    getGames: state => state.game || [],
}

const actions = {
    resetRequest({commit}){
        commit('SET_REQUEST',{...requestInit})
    },
    resetItem({commit}){
        commit('SET_ITEM',{...itemInit})
    },
    setItem({commit}, value){
        commit('SET_ITEM',{...value})
    },
    
    async getGame({commit }) {
        try {
            const url = `/game`
            var response = await getAllData(url);
            commit('SET_RESPONSE', response.data)
            commit('SET_GAME', response.data.data);
        } catch (e) {
            if (e.response) {
                commit('SET_MSG_FAILED', e.response)
            } else {
                commit('SET_FAILED')
            }
        }
    },
    async submit({commit }, data) {
        try {
            const url = `/game/${data.id}`
            var response = await postData(url, data);
            commit('SET_RESPONSE', response.data);
        } catch (e) {
            if (e.response) {
                commit('SET_MSG_FAILED', e.response)
            } else {
                commit('SET_FAILED')
            }
        }
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.msg = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        state.msg = error?.data?.msg || 'Jaringan Bermasalah'
        state.status = error?.data?.status || 0
    },
    SET_RESPONSE(state, response) {
        state.msg = response.msg
        state.status = response.status
    },
    SET_DATA(state, value) {
        state.data = value
    },
    SET_REQUEST(state, value){
        state.request = value
    },
    SET_ITEM(state, value){
        state.item = value
    },
    SET_GAME(state, value) {
        state.game = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}