import { 
    getDataWithParams,
    destroyData,
    postData,
    getAllData
} from '@/store/config/method'

const requestInit = {
    page: 1,
    show: 10,
    sortBy: null,
    sorting: null,
    search: null,
}
const itemInit = {
    id : null,
    id_bank : null,
    name : null,
    username : null,
    password : null,
    hidden_password : null,
    email : null,
    no_ponsel : null,
    no_rek : null,
    nama_rek : null,
    status_player : null,
    saldo : null,
    saldo_backup : null,
    last_login : null,
    ip_address : null,
    browser : null,
    created_at : null,
    updated_by : null,
    approved_by : null,
    note: null,
    id_to_rek: null
}

const initialState = () => ({
    msg: null,
    status: 0,
    data: {},
    player: {},
    request: {...requestInit},
    item: {...itemInit},
    pasaranStatus: [
        {
            status: 0,
            label: 'Offline',
            type: 'primary'
        },
        {
            status: 1,
            label: 'Online',
            type: 'success'
        },
    ],
})

const state = initialState

const getters = {
    getMsg: state => state.msg,
    getStatus: state => state.status,
    getData: state => state.data || {},
    getPlayer: state => state.player || {},
    getRequest: state => state.request,
    getItem: state => state.item,
    getPasaranStatus: state => (status) => state.pasaranStatus.find(x => x.status == status),
}

const actions = {
    resetRequest({commit}){
        commit('SET_REQUEST',{...requestInit})
    },
    resetItem({commit}){
        commit('SET_ITEM',{...itemInit})
    },
    setItem({commit}, value){
        commit('SET_ITEM',{...value})
    },

    async getProfilePlayer({
        commit
    }, data){
        try {
            const url = `/player-profile/${data.id}`
            const response = await getAllData(url);
            commit('SET_RESPONSE', response.data);
            commit('SET_PLAYER', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },

    async fetchData({
        commit, state
    }){
        try {
            const response = await getDataWithParams(`/all-player`,state.request);
            commit('SET_RESPONSE', response.data);
            commit('SET_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },

    async getReferralPlayer({commit, state}, id) {
        try {
            const url = `/player/referral/${id}`
            const response = await getDataWithParams(url,state.request);
            commit('SET_RESPONSE', response.data);
            commit('SET_DATA', response.data.data);
        } catch (err) {
            if (err.response) {
                commit('SET_FAILED_MESSAGE', err.response);
            } else {
                commit('SET_FAILED')
            }
        }
    },

    async fetchDataRegister({
        commit, state
    }){
        try {
            const response = await getDataWithParams(`/all-player/new`,state.request);
            commit('SET_RESPONSE', response.data);
            commit('SET_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },

    async fetchDataReject({
        commit, state
    }){
        try {
            const response = await getDataWithParams(`/all-player/rejected`,state.request);
            commit('SET_RESPONSE', response.data);
            commit('SET_DATA', response.data.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },

    async verifikasi({commit }, {payload, id}) {
        try {
            const url = `/player/verify/${id}`
            const response = await postData(url, payload)
            commit('SET_RESPONSE', response.data)
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },

    async reject({commit }, {payload, id}) {
        try {
            const url = `/player/reject/${id}`
            const response = await postData(url, payload)
            commit('SET_RESPONSE', response.data)
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },

    async calculateAllPlayer({commit}, data) {
        try {
            const response = await postData('/calculate-all', data)
            commit('SET_RESPONSE', response.data)
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },

    async calculatePlayer({commit}, data) {
        try {
            const url = `/calculate-player/${data.id}`
            const payload = {...data}
            delete payload.id
            const response = await postData(url, payload)
            commit('SET_RESPONSE', response.data)
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },

    async update({commit }, data) {
        try {
            const url = `/player/update/${data.id}`
            const payload = {...data}
            delete payload.id
            const response = await postData(url, payload)
            commit('SET_RESPONSE', response.data)
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    
    async destroy({
        commit
    }, data) {
        try {
            const url = `/player/${data.id}`
            const response = await destroyData(url);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.msg = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        state.msg = error?.data?.msg || 'Jaringan Bermasalah'
        state.status = error?.data?.status || 0
    },
    SET_RESPONSE(state, response) {
        state.msg = response.msg
        state.status = response.status
    },
    SET_DATA(state, value) {
        state.data = value
    },
    SET_PLAYER(state, value) {
        state.player = value
    },
    SET_REQUEST(state, value){
        state.request = value
    },
    SET_ITEM(state, value){
        state.item = value
    },
    SET_BANKS(state, value){
        state.pasarans = value
    },
    SET_FILE_NEW_NAME(state, response){
        state.fileDetail = {
            name: response.fileName,
            fullName: response.fullName
        }
    },
    SET_FILE_DATA(state, value){
        state.fileData = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}