import {
    getAllData,
    postData,
} from '@/store/config/method'

const itemInit = {
    id: null,
    name: null,
    username: null,
    old_password: null,
    new_password: null,
    confirm_password: null,
    change_password: false,
}

const initialState = () => ({
    msg: "Error",
    status: 0,
    item: {...itemInit},
    user: {},
    token: null,
    appkey: null,
})

function makeid() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < 20; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const state = initialState

const getters = {
    getToken: state => state.token,
    getAppkey: state => state.appkey,
    getUser: state => state.user,
    getRole: state => state.user.role_name,
    getId: state => state.user.id,
    getItem: state => state.item,
    getStatus: state => state.status,
    getMsg: state => state.msg,
}

const mutations = {
    SET_FAILED(state) {
        state.msg = 'Error'
        state.status = 0
    },
    SET_MSG_FAILED(state, err) {
        if (err.status == 400) {
            state.msg = err.data.msg
            state.status = err.data.status
        } else {
            state.msg = 'Error'
            state.status = 0
        }
    },
    SET_RESPONSE(state, response) {
        state.msg = response.msg
        state.status = response.status
    },
    SET_ITEM(state, value) {
        state.item = value
    },
    SET_USER(state, source) {
        state.response = source
        state.user = source.data
        localStorage.setItem('una4d-role',source.data.role_name)
    },
    SET_TOKEN(state, data) {
        state.response = data
        state.user = data.user
        state.token = data.token
        const key = makeid()
        state.appkey = key
        localStorage.setItem('appkey',key)
        localStorage.setItem('una4d-role',data.user.role_name)
    },
    resetState(state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    }
}

const actions = {
    setItem({commit }, value) {
        commit('SET_ITEM',{...value})
    },
    resetItem({commit }) {
        commit('SET_ITEM', {...itemInit})
    },

    async login({commit, rootState }, data) {
        try {
            const response = await postData(`/login`, data)
            commit('SET_RESPONSE', response.data)
            commit('SET_TOKEN', response.data)
            rootState.isLoggedIn = true
        } catch (e) {
            if (e.response) {
                commit('SET_MSG_FAILED', e.response)
            } else {
                commit('SET_FAILED')
            }
        }
    },

    async update({commit }, data) {
        try {
            const response = await postData(`/update-user`, data)
            commit('SET_RESPONSE', response.data)
        } catch (e) {
            if (e.response) {
                commit('SET_MSG_FAILED', e.response)
            } else {
                commit('SET_FAILED')
            }
        }
    },

    async myProfile({commit }) {
        try {
            const response = await getAllData('/user');
            commit('SET_USER', response.data);
        } catch (e) {
            if (e.response) {
                commit('SET_MSG_FAILED', e.response)
            } else {
                commit('SET_FAILED')
            }
        }
    },
    async logoutUser({
        commit
    }, data) {
        try {
            const response = await postData(`/logout`, data)
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            commit('SET_FAILED')
        }
    },

    resetState({commit}) {
        commit('resetState')
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}