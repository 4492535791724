<template>
  <div id="app">
    <r-sidebar v-if="$store.getters['getisLoggedIn'] && currentRoute != 'Login'" />
    <r-navbar v-if="$store.getters['getisLoggedIn'] && currentRoute != 'Login'" />
    <main :class="$store.getters['getisLoggedIn'] && currentRoute != 'Login' ? !collapse ? 'r-content' : 'r-content r-move-container' : 'r-content-fullwidth'">
      <div class="r-container" >
        <router-view />
      </div>
    </main>
    <r-footer v-if="$store.getters['getisLoggedIn'] && currentRoute != 'Login'" />
    <r-snackbar />
  </div>
</template>
<style lang="scss">

.r-content-fullwidth {
  width: 100vw;
  height: 100vh;
  background: #fafbfc;
}
.r-move-container{
  width: 100% !important;
  padding-left: 0px !important;
}
.r-content {
  transition: all 0.2s ease;
  width: calc(100% - 230px);
  height: calc(100% - 125px);
  min-height: calc(100vh - 125px);
  background: #F6F6F6;
  padding-left: 230px;
  padding-top: 80px;
  padding-bottom: 45px;
}

</style>
<script>
import update from './mixins/update'

function loadMain(view) {
  return () =>
    import(
      /* webpackChunkName: "main-[request]" */ `./components/main/${view}.vue`
    );
}
export default {
  mixins: [update],
  components: {
    "r-navbar": loadMain("Navbar"),
    "r-sidebar": loadMain("Sidebar"),
    "r-snackbar": loadMain("Snackbar"),
    "r-footer": loadMain("Footer"),
  },
  computed:{
    collapse(){
      return this.$store.getters['getCollapse']
    },
    currentRoute(){
      return this.$route.name
    }
  },
  data: () => ({
    userId: null,
    userToken: null,
  }),
  methods:{
    
  }
};
</script>