function loadPelanggan(view) {
    return () => import( /* webpackChunkName: "view-bank" */ `../views/Pelanggan/${view}.vue`)
}

const routePelanggan = [
    {
        path: '/pelanggan',
        name: 'ViewDataPelanggan',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: []
        },
        component: loadPelanggan('ViewDataPelanggan')
    },
    {
        path: '/pelanggan-register',
        name: 'ViewDataPelangganRegister',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: ['superadmin']
        },
        component: loadPelanggan('ViewDataPelangganRegister')
    },
    {
        path: '/pelanggan-pending',
        name: 'ViewDataPelangganPending',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: ['superadmin']
        },
        component: loadPelanggan('ViewDataPelangganPending')
    },
    {
        path: '/pesan',
        name: 'ViewDataPesan',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: ['superadmin']
        },
        component: loadPelanggan('ViewDataPesan')
    },
    {
        path: '/pesan-online',
        name: 'ViewDataPesanOnline',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: ['superadmin']
        },
        component: loadPelanggan('ViewDataPesanOnline')
    },
    {
        path: '/total-register',
        name: 'ViewDataTotalRegister',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: ['superadmin']
        },
        component: loadPelanggan('ViewDataTotalRegister')
    },
    {
        path: '/downline-player/:id',
        name: 'ViewDownlinePelanggan',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: ['superadmin']
        },
        component: loadPelanggan('ViewDownlinePelanggan')
    },
]

export default routePelanggan