import { 
    getAllData,
    getDataWithParams,
    postData
} from '@/store/config/method'

const requestInit = {
    page: 1,
    show: 10,
    sortBy: null,
    sorting: null,
    search: null,
}
const itemInit = {
    id: null,
    max: null,
    diskon: null,
    kei: null,
    menang: null,
}

const initialState = () => ({
    msg: null,
    status: 0,
    data: {},
    detail: {},
    request: {...requestInit},
    item: {...itemInit},
    periode: [],
})

const state = initialState

const getters = {
    getMsg: state => state.msg,
    getStatus: state => state.status,
    getData: state => state.data || {},
    getRequest: state => state.request,
    getItem: state => state.item,
    getPeriodes: state => state.periode || [],
    getDetail: state => state.detail
}

const actions = {
    resetRequest({commit}){
        commit('SET_REQUEST',{...requestInit})
    },
    resetItem({commit}){
        commit('SET_ITEM',{...itemInit})
    },
    setItem({commit}, value){
        commit('SET_ITEM',{...value})
    },
    
    async getPeriode({commit }) {
        try {
            const url = `/all-periode`
            var response = await getAllData(url);
            commit('SET_RESPONSE', response.data)
            commit('SET_PERIODE', response.data.data);
        } catch (e) {
            if (e.response) {
                commit('SET_MSG_FAILED', e.response)
            } else {
                commit('SET_FAILED')
            }
        }
    },
    async getDetailPeriode({commit }, id) {
        try {
            const url = `/periode/${id}`
            var response = await getAllData(url);
            commit('SET_RESPONSE', response.data)
            commit('SET_DETAIL', response.data.data);
        } catch (e) {
            if (e.response) {
                commit('SET_MSG_FAILED', e.response)
            } else {
                commit('SET_FAILED')
            }
        }
    },
    async getAllAnalisaNomor({commit }, data) {
        try {
            const url = `/analisa-nomor/${data.id}`
            var response = await getDataWithParams(url, data);
            commit('SET_RESPONSE', response.data);
            commit('SET_DATA', response.data.data);
        } catch (e) {
            if (e.response) {
                commit('SET_MSG_FAILED', e.response)
            } else {
                commit('SET_FAILED')
            }
        }
    },
    async store({
        commit
    }, {data, id}) {
        try {
            const url = `/analisa-nomor/${id}`
            const response = await postData(url,data);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    async update({
        commit
    }, data) {
        try {
            const url = `/update-nomor/${data.id_detail}`
            const response = await postData(url,data);
            commit('SET_RESPONSE', response.data);
        } catch (err) {
            if(err.response){
                commit('SET_FAILED_MESSAGE', err.response);
            }else{
                commit('SET_FAILED')
            }
        }
    },
    resetState({commit}){
        commit('resetState')
    }
}

const mutations = {
    SET_FAILED(state) {
        state.msg = 'Jaringan Bermasalah'
        state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
        state.msg = error?.data?.msg || 'Jaringan Bermasalah'
        state.status = error?.data?.status || 0
    },
    SET_RESPONSE(state, response) {
        state.msg = response.msg
        state.status = response.status
    },
    SET_DATA(state, value) {
        state.data = value
    },
    SET_REQUEST(state, value){
        state.request = value
    },
    SET_ITEM(state, value){
        state.item = value
    },
    SET_DETAIL(state, value){
        state.detail = value
    },
    SET_PERIODE(state, value) {
        state.periode = value
    },
    resetState (state) {
        const initial = initialState()
        Object.keys(initial).forEach(key => { state[key] = initial[key] })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}