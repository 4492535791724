function loadBank(view) {
    return () => import( /* webpackChunkName: "view-bank" */ `../views/Bank/${view}.vue`)
}

const routeBank = [
    {
        path: '/bank',
        name: 'ViewBank',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: []
        },
        component: loadBank('ViewBank')
    },
    {
        path: '/rekening',
        name: 'ViewRekening',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: ['superadmin']
        },
        component: loadBank('ViewRekening')
    },
    {
        path: '/rekap-bank',
        name: 'ViewRekapBank',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: ['superadmin']
        },
        component: loadBank('ViewRekapBank')
    },
    {
        path: '/rekap-depo',
        name: 'ViewRekapDepo',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: ['superadmin']
        },
        component: loadBank('ViewRekapDepo')
    },
    {
        path: '/rekap-wd',
        name: 'ViewRekapWd',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: ['superadmin']
        },
        component: loadBank('ViewRekapWd')
    },
]

export default routeBank