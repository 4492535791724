function loadPengguna(view) {
    return () => import( /* webpackChunkName: "view-pengguna" */ `../views/Pengguna/${view}.vue`)
}

const routePengguna = [
    {
        path: '/profile',
        name: 'profile',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: []
        },
        component: loadPengguna('Profile')
    },
    {
        path: '/user',
        name: 'ListUserView',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: ['superadmin']
        },
        component: loadPengguna('MainView')
    },
    {
        path: '/log-admin',
        name: 'ListLogAdmin',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: ['superadmin']
        },
        component: loadPengguna('ViewDataLogAdmin')
    },
]

export default routePengguna