import store from "../store"
import localForage from 'localforage'
import pengguna from './pengguna'
import bank from './bank'
import setting from './setting'
import pelanggan from './pelanggan'
import transaction from './transaction'

function loadDashboard(view) {
    return () => import( /* webpackChunkName: "dashboard-[request]" */ `../views/${view}.vue`)
}

const routes = [
    ...pengguna,
    ...bank,
    ...setting,
    ...pelanggan,
    ...transaction,
    {
        path: '/',
        name: 'Dashboard',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: []
        },
        component: loadDashboard('Dashboard')
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            requiresAuth: false,
            beforelogin: true,
            roleVerif: false,
            role: []
        },
        component: () => import( /* webpackChunkName: "component-login" */ `../views/Login.vue`)
    },
    {
        path: '/logout',
        name: 'logoutComponent',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: []
        },
        component: () => import( /* webpackChunkName: "component-logout" */ `../views/Logout.vue`)
    },
    {
        path: '*',
        name: 'NotFound',
        meta: {
            requiresAuth: false,
            roleVerif: false,
            role: []
        },
        component: () => import( /* webpackChunkName: "component-notfound" */ `../views/NotFound.vue`)
    },
    
]
const routerFilter = async (routes) => {
    await store.restored;
    const temp = await localForage.getItem("una4d")
    let myrole = null
    if(temp){
        myrole = temp.auth.user.role
    }
    const isLoggedIn = store.getters['getisLoggedIn']
    return routes.filter((row) => {
        return (row.meta.requiresAuth == isLoggedIn && ((row.meta.roleVerif && row.meta.role.some(role => role == myrole) || !row.meta.roleVerif))) || !row.meta.requiresAuth
    })
}
export default routerFilter(routes)