function loadSetting(view) {
    return () => import( /* webpackChunkName: "view-setting" */ `../views/Setting/${view}.vue`)
}

const routeSetting = [
    {
        path: '/setting/ip',
        name: 'ViewSettingIp',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: []
        },
        component: loadSetting('ViewSettingIp')
    },
    {
        path: '/setting/web',
        name: 'ViewSettingWeb',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: ['superadmin']
        },
        component: loadSetting('ViewSettingWeb')
    },
    {
        path: '/setting/pasaran',
        name: 'ViewSettingPasaran',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: ['superadmin']
        },
        component: loadSetting('ViewSettingPasaran')
    },
    {
        path: '/setting/pasaran/hari/:id',
        name: 'ViewSettingHariPasaran',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: ['superadmin']
        },
        component: loadSetting('ViewSettingHariPasaran')
    },
    {
        path: '/setting/games',
        name: 'ViewSettingGame',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: ['superadmin']
        },
        component: loadSetting('ViewSettingGame')
    },
    {
        path: '/setting/periode',
        name: 'ViewSettingPeriode',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: ['superadmin']
        },
        component: loadSetting('ViewSettingPeriode')
    },
    {
        path: '/periode/analisa/:id/:nomor',
        name: 'ViewAnalisaNomor',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: ['superadmin']
        },
        component: loadSetting('ViewAnalisaNomor')
    },
    {
        path: '/setting/promo',
        name: 'ViewSettingPromo',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: ['superadmin']
        },
        component: loadSetting('ViewSettingPromo')
    },
    // {
    //     path: '/setting/spin',
    //     name: 'ViewSettingSpin',
    //     meta: {
    //         requiresAuth: true,
    //         roleVerif: false,
    //         role: ['superadmin']
    //     },
    //     component: loadSetting('ViewSettingSpin')
    // },
    {
        path: '/setting/slider',
        name: 'ViewSettingSlider',
        meta: {
            requiresAuth: true,
            roleVerif: false,
            role: ['superadmin']
        },
        component: loadSetting('ViewSettingSlider')
    },
]

export default routeSetting